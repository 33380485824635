'use client';

import classNames from 'classnames';
import { useLocale } from 'next-intl';
import { useCloseCallback } from '@/src/utils/useCloseCallback.ts';
import { LocaleCode } from '@/src/i18n/config';
import { setUserLocale } from '@/src/i18n/localeService.ts';
import Popover, { PopoverPlacement } from '@/src/components/UIKit/Popover/Popover.tsx';
import Svg from '@/src/components/UIKit/Svg.tsx';
import styles from './LanguageSelector.module.scss';

type Locale = {
  code: LocaleCode,
  name: string,
  flag: string,
};

const locales: Locale[] = [{
  code: LocaleCode.EN,
  name: 'ENG',
  flag: 'en-lang',
}, {
  code: LocaleCode.DE,
  name: 'DE',
  flag: 'de-lang',
}];

const LanguageSelector = () => {
  const localeCode = useLocale();
  const activeLanguage = locales.find(lang => lang.code === localeCode)!;

  const { closeItem: closePopover, closeItemCallback: closeCallback } = useCloseCallback();

  return (
    <Popover
      paperClassName={styles.content}
      placement={PopoverPlacement.SELECT}
      triggerButton={(
        <div className={classNames(styles.languageItem, styles.currentLanguage)}>
          <div className={styles.currentLanguage__content}>
            <Svg
              className={styles.languageItem__flag}
              name={activeLanguage.flag}
            />
            {activeLanguage.name}
          </div>
          <Svg
            className={styles.currentLanguage__arrow}
            name='angleDown'
          />
        </div>
      )}
      closeCallback={closeCallback}
    >
      <ul>
        {locales.filter(lang => lang.code !== localeCode).map(lang => (
          <li key={lang.code}>
            <button
              type='button'
              className={styles.languageItem}
              onClick={() => {
                closePopover();
                setUserLocale(lang.code);
              }}
            >
              <Svg
                className={styles.languageItem__flag}
                name={lang.flag}
              />
              {lang.name}
            </button>
          </li>
        ))}
      </ul>
    </Popover>
  );
};

export default LanguageSelector;
