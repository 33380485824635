import { PopoverOrigin } from '@mui/material/Popover/Popover';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { Popover as MuiPopover } from '@mui/material';
import styles from './Popover.module.scss';

export enum PopoverPlacement {
  TOP = 'top',
  RIGHT = 'right',
  BOTTOM = 'bottom',
  LEFT = 'left',
  BOTTOM_RIGHT = 'bottom right',
  BOTTOM_LEFT = 'bottom left',
  TOP_CENTER = 'top center',
  SELECT = 'select',
  SELECT_TOP = 'select top',
}

const placementOptions: {
  [key: string]: {
    anchorOrigin: PopoverOrigin,
    transformOrigin: PopoverOrigin,
  },
} = {
  [PopoverPlacement.RIGHT]: {
    anchorOrigin: {
      vertical: 'center',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'center',
      horizontal: 'left',
    },
  },
  [PopoverPlacement.LEFT]: {
    anchorOrigin: {
      vertical: 'center',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'center',
      horizontal: 'right',
    },
  },
  [PopoverPlacement.TOP]: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  },
  [PopoverPlacement.BOTTOM]: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  },
  [PopoverPlacement.BOTTOM_RIGHT]: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'right',
    },
  },
  [PopoverPlacement.BOTTOM_LEFT]: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
  [PopoverPlacement.TOP_CENTER]: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  },
  [PopoverPlacement.SELECT]: {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
  },
  [PopoverPlacement.SELECT_TOP]: {
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
  },
};

type PopoverProps = {
  paperClassName?: string,
  children: React.ReactNode,
  placement: PopoverPlacement,
  triggerButton: React.ReactNode,
  closeCallback?: (func: (e: Event) => void) => void,
  className?: string,
  disabled?: boolean,
  onOpen?: () => void,
  onClose?: () => void,
  withoutArrow?: boolean,
  disableTransition?: boolean,
};

const Popover = ({
  paperClassName, children, placement, triggerButton, closeCallback, className, disabled, onOpen, onClose, withoutArrow, disableTransition,
}: PopoverProps) => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event?.stopPropagation();
    setAnchorEl(event.currentTarget);
    onOpen?.();
  };

  const handleClose = (e: Event) => {
    e?.stopPropagation();
    setAnchorEl(null);
    onClose?.();
  };

  useEffect(() => {
    closeCallback?.(e => handleClose(e));
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className={classNames(styles.popover, className)}>
      <div className={styles.triggerButton__wrapper}>
        <button
          type='button'
          aria-describedby={id}
          onClick={handleClick}
          className={classNames(styles.button, { [styles.active]: open })}
          disabled={disabled}
        >
          {triggerButton}
        </button>
      </div>
      <MuiPopover
        id='main'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        transitionDuration={disableTransition ? 0 : undefined}
        classes={{
          paper: classNames(styles.paper, paperClassName, {
            [styles.left]: placement === PopoverPlacement.LEFT,
            [styles.right]: placement === PopoverPlacement.RIGHT,
            [styles.bottom]: placement === PopoverPlacement.BOTTOM,
            [styles.top]: placement === PopoverPlacement.TOP,
            [styles.bottomRight]: placement === PopoverPlacement.BOTTOM_RIGHT,
            [styles.bottomLeft]: placement === PopoverPlacement.BOTTOM_LEFT,
            [styles.select]: placement === PopoverPlacement.SELECT,
            [styles.selectTop]: placement === PopoverPlacement.SELECT_TOP,
            [styles.withoutArrow]: withoutArrow,
          }),
          root: styles.popoverRoot,
        }}
        anchorOrigin={placementOptions[placement].anchorOrigin}
        transformOrigin={placementOptions[placement].transformOrigin}
      >
        {children}
      </MuiPopover>
    </div>
  );
};

export default Popover;
